<template>
	<div class="recordDetailWrap">
		<div class="header">
			<el-button type="text" icon="el-icon-arrow-left" style="margin-left: 20px" @click="handleClose">文件列表</el-button>
			<div>访谈录音</div>
			<el-button type="text" style="margin-right: 20px" @click="handleReset">重置</el-button>
		</div>
		<div class="content">
			<!-- <el-input
				type="textarea"
				style="padding: 30px 80px; height: calc(100vh - 160px)"
				v-model="content"
				autosize
				@blur="onBlur"
				@focus="onFocus"
			/> -->
			<div style="padding: 30px 80px; height: calc(100vh - 160px); font-size: 14px; line-height: 20px">
				<div style="overflow-y: auto; height: 100%">
					<p
						v-for="(val, inx) in originalRecord"
						:key="val.start_time"
						contentEditable
						style="outline: none"
						@click="() => handleRowText(val)"
						@input="(e) => debounceChangeText(e, inx)"
					>
						{{ val.result.text }}
					</p>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="recorderAudio">
				<audio @timeupdate="updateProgress" controls ref="audioRef" style="display: none" @canplay="getDuration">
					<source :src="fileurl" type="audio/wav" />
					您的浏览器不支持音频播放
				</audio>
				<div class="audioPanel">
					<div class="slidList">
						<el-progress
							color="#51C0F0"
							class="audioProgress"
							:percentage="value"
							:text-inside="true"
							:show-text="false"
							@click="handleTime"
							:stroke-width="10"
						/>
						<div class="playBtn">
							<el-button icon="el-icon-d-arrow-left" circle size="mini" @click="audioJump('rewind')"></el-button>
							<el-button v-show="audioStatus == 0" icon="el-icon-video-play" circle type="primary" @click="playAudio"></el-button>
							<el-button v-show="audioStatus == 1" icon="el-icon-video-pause" circle type="primary" @click="playAudio"></el-button>
							<el-button icon="el-icon-d-arrow-right" circle size="mini" @click="audioJump('forward')"></el-button>
							<span class="timers">{{ videoStart }}/{{ transTime(duration) }}</span>
							<span class="speed">
								倍速：<el-popover placement="bottom-start" width="40" trigger="click" popper-class="speedChecks">
									<div v-for="val in speedList" :key="val.label" class="speedItem" @click="() => checkSpeed(val.value)">
										{{ val.label }}
									</div>
									<el-button type="text" slot="reference">{{ playbackRate }}x</el-button>
								</el-popover>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { getUrlParams } from '@/utils/util';
import { debounce } from 'loadsh';

export default {
	name: 'recordDetail',

	data() {
		return {
			content: '',
			fileurl: '',
			audioStatus: 0,
			videoStart: '00:00:00',
			value: 0,
			duration: 0,
			isToPla: false,
			playbackRate: 1,
			resizeTime: 5, // seconds
			speedList: [
				{ label: '1x', value: 1 },
				{ label: '1.5x', value: 1.5 },
				{ label: '2x', value: 2 },
				{ label: '2.5x', value: 2.5 },
				{ label: '3x', value: 3 }
			],
			originalRecord: [],
			debounceChangeText: () => {},
			textList: [],
			recordId: '',
			reportId: ''
		};
	},

	mounted() {
		const { params } = this.$route.query;
		const { id, obsFilePath, reportId } = JSON.parse(getUrlParams(params));

		this.fileurl = obsFilePath;
		this.recordId = id;
		this.reportId = reportId;
		// 获取文字结果
		this.getTranslationResult();
		this.debounceChangeText = debounce(this.resultTextChange, 200);
		document.querySelector('.audioProgress').addEventListener('click', this.handleTime);
		document.addEventListener('keydown', this.handleKeyDown);
	},

	methods: {
		getTranslationResult() {
			this.$axios.post('/v1/internal/talent/getTranslationResult', { request: { recordId: this.recordId } }).then((res) => {
				if (res.code) {
					return;
				}
				const { content, originalRecord } = res;

				this.content = content;
				if (originalRecord) {
					this.originalRecord = JSON.parse(originalRecord);
					this.textList = this.originalRecord.map(({ result }) => result.text);
				}
			});
		},
		handleClose() {
			window.close();
		},
		handleReset() {
			this.$confirm('重置将恢复到系统转写结果，不可撤销', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios.post('/v1/internal/talent/resetTranslationResult', { request: { recordId: this.recordId } }).then((res) => {
					if (res.code) {
						return;
					}
					this.$message.success('重置成功');
					this.getTranslationResult();
				});
			});
		},
		getDuration() {
			this.duration = this.$refs.audioRef.duration;
		},
		// 播放暂停控制
		playAudio() {
			this.isToPla = true;
			const audio = this.$refs.audioRef; // 获取audio元素

			if (audio.paused) {
				audio.play();
				this.audioStatus = 1;
			} else {
				audio.pause();
				this.audioStatus = 0;
			}
		},
		audioJump(type) {
			const audio = this.$refs.audioRef;

			if (audio) {
				if (type === 'rewind') {
					this.$refs.audioRef.currentTime -= this.resizeTime;
				} else if (type === 'forward') {
					this.$refs.audioRef.currentTime += this.resizeTime;
				}
			}
		},
		// 更新进度条与当前播放时间
		updateProgress(e) {
			const value = e.target.currentTime / this.duration;

			this.value = value * 100;
			if (e.target.currentTime === this.duration) {
				this.audioStatus = 0;
				return;
			}
			this.videoStart = this.transTime(this.$refs.audioRef.currentTime);
		},
		/**
		 * 音频播放时间换算
		 */
		transTime(value) {
			return moment.utc(Math.floor(value * 1000)).format('HH:mm:ss');
		},
		handleTime(e) {
			const playRate = Math.floor((e.offsetX / e.target.offsetWidth) * 100) / 100;
			const audio = this.$refs.audioRef;

			audio.currentTime = playRate * this.duration;
			audio.play();
			this.audioStatus = 1;
		},
		onBlur() {
			this.lockRewind = false;
		},
		onFocus() {
			this.lockRewind = true;
		},
		// 快进快退 功能
		handleKeyDown(e) {
			if (!this.lockRewind) {
				if (e.keyCode === 37) {
					this.audioJump('rewind');
				} else if (e.keyCode === 39) {
					this.audioJump('forward');
				}
			}
		},
		// 调整速度
		checkSpeed(val) {
			const audio = this.$refs.audioRef;

			if (audio) {
				this.playbackRate = val;
				audio.playbackRate = val;
				this.$nextTick(() => {
					document.querySelector('div').click();
				});
			}
		},
		// 点击 行翻译文字
		handleRowText(row) {
			const audio = this.$refs.audioRef;

			if (audio) {
				audio.currentTime = row.start_time / 1000;
				audio.play();
				this.audioStatus = 1;
			}
		},
		// 编辑文字
		resultTextChange(e, inx) {
			const inner = e.target.innerHTML;

			this.textList[inx] = inner;

			if (this.timerOut) {
				clearTimeout(this.timerOut);
			}
			this.timerOut = setTimeout(() => {
				this.saveChangeText();
			}, 2000);
		},
		// 保存编辑过的文字
		saveChangeText() {
			console.log(this.textList, 'saveChangeText');
			this.$axios
				.post('/v1/internal/talent/updateTranslationResult', {
					request: { recordId: this.recordId, reportId: this.reportId, content: this.textList.join('\n') }
				})
				.then((res) => {
					if (res.code) {
						return;
					}
					this.$message.success('修改成功');
					// this.getTranslationResult();
				});
		}
	},
	beforeDestroy() {
		document.querySelector('.audioProgress').removeEventListener('click', this.handleTime);
		document.removeEventListener('keydown', this.handleKeyDown);
	}
};
</script>

<style lang="scss" scoped>
.recordDetailWrap {
	.header {
		height: 60px;
		background-color: #fff;
		margin: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.content {
		height: calc(100vh - 180px);
		background-color: #fff;
		margin: 0 10px;
		::v-deep textarea {
			height: 100% !important;
		}
	}
	.footer {
		height: 80px;
		background-color: #fff;
		margin: 10px;
		.audioPanel {
			.slidList {
				.timers {
					color: #bdbdbd;
					font-family: PingFang SC;
					font-size: 12px;
					margin-left: 10px;
				}
				.speed {
					font-size: 14px;
					margin-left: 60px;
					color: #666;
				}
			}
			.audioProgress {
				width: 100%;
				cursor: pointer;
				padding: 10px;
			}
			.playBtn {
				margin: 0 20px;
			}
		}

		::v-deep {
			.van-progress__pivot {
				display: none;
			}
		}
	}
}
</style>
<style lang="scss">
.speedChecks {
	.speedItem {
		height: 30px;
		line-height: 30px;
		cursor: pointer;
		&:hover {
			color: #409eff;
		}
	}
}
</style>
